<template>
  <b-card class="card-developer-meetup" border-variant="primary">
    <template v-if="showReservationButton">
      <div v-if="!isPackageUnavailable">
        <b-button
          v-if="canReserved"
          size="lg"
          variant="warning"
          class="float-right"
          @click="bookPackage()"
          >Réserver</b-button
        >
        <div v-else class="float-right">
          <h3><b-badge variant="info">Sur demande</b-badge></h3>
        </div>
      </div>
      <div v-else>
        <div class="float-right">
          <h3><b-badge>Sur demande</b-badge></h3>
        </div>
      </div>
    </template>
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>En bref</span>
        </template>
        <b-row>
          <b-col md="3">
            <b-carousel img-width="200" img-height="200" controls indicators>
              <b-carousel-slide :img-src="pkg.image_url"> </b-carousel-slide>
            </b-carousel>
          </b-col>
          <b-col md="7">
            <div class="meetup-header d-flex align-items-center">
              <div class="meetup-day">
                <h6 class="mb-0">À partir de</h6>
                <h3 class="mb-0">{{ getMainPricing.price }}$</h3>
                <small>en occ. {{ getMainPricing.occupation }}</small>
              </div>
              <div class="my-auto">
                <h4 class="mb-25">
                  {{ pkg.name }}
                </h4>
                <small class="mb-0"> </small>
              </div>
            </div>
            <b-row>
              <b-col md="6">
                <b-media no-body class="mb-1">
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-warning" size="34">
                      <feather-icon icon="UsersIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body style="margin: auto">
                    <h5 class="mb-0">Occupation(s)</h5>
                    <small>{{ occupationType.join(", ") }}</small>
                  </b-media-body>
                </b-media>
                <b-media no-body class="mb-1">
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-info" size="34">
                      <feather-icon icon="HomeIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body style="margin: auto">
                    <h5 class="mb-0">Hébergement(s)</h5>
                    <div v-if="isRoomsEmpty(pkg.rooms)">
                      <small>Aucun hébergement</small>
                    </div>
                    <div>
                      <div v-for="room in pkg.rooms" :key="room.id">
                        <small
                          ><i>{{ room.room_category }}</i> -
                          {{ getRoomTitle(room) }}
                          <!-- (2 nuits) -->
                        </small>
                        <br />
                      </div>
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col md="6">
                <b-media no-body class="mb-1">
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-success" size="34">
                      <feather-icon icon="GlobeIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body style="margin: auto">
                    <h5 class="mb-0">Transport</h5>
                    <div v-if="currentGroup.has_coach_transportation">
                      <div
                        v-for="flightTransportation in currentFlightTransportations"
                        :key="flightTransportation.id"
                      >
                        <small
                          >{{ getDepartureCity(flightTransportation) }} -
                          {{ getArrivalCity(flightTransportation) }}
                        </small>
                      </div>
                    </div>
                    <div v-else>
                      <small>Aucun vols </small>
                    </div>
                  </b-media-body>
                </b-media>

                <b-media no-body class="mb-1">
                  <b-media-aside class="mr-1">
                    <b-avatar rounded variant="light-success" size="34">
                      <feather-icon icon="BusIcon" size="18" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body style="margin: auto">
                    <h5 class="mb-0">Transport par navette/autocar</h5>
                    <div v-if="currentGroup.has_coach_transportation">
                      <div
                        v-for="coachTransportation in currentCoachTransportations"
                        :key="coachTransportation.id"
                      >
                        <small
                          >{{ getDepartureCity(coachTransportation) }} -
                          {{ getArrivalCity(coachTransportation) }}
                        </small>
                      </div>
                    </div>
                    <div v-else>
                      <small>Aucun transport </small>
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab v-if="showAccommodationTab">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Hébergement</span>
        </template>
        <AccommodationCard
          :current-accommodations="currentAccommodations"
          :rooms="pkg.rooms"
        />
      </b-tab>
      <b-tab v-if="showTransportationTab">
        <template #title>
          <feather-icon icon="NavigationIcon" />
          <span>Transport aérien</span>
        </template>
        <template v-if="currentGroup.has_flight_transportation">
          <b-row
            v-for="transportation in currentFlightTransportations"
            :key="transportation.id"
          >
            <b-col md="6">
              <TransportationSegmentCard
                v-if="
                  transportation.departures &&
                  transportation.departures.length > 0
                "
                transport-type="flight"
                direction="Départ"
                :transportation="transportation.departures"
              />
            </b-col>
            <b-col md="6">
              <TransportationSegmentCard
                v-if="
                  transportation.returns && transportation.returns.length > 0
                "
                transport-type="flight"
                direction="Retour"
                :transportation="transportation.returns"
              />
            </b-col>
          </b-row>
        </template>

        <template v-if="currentGroup.has_coach_transportation">
          <b-row
            v-for="transportation in currentCoachTransportations"
            :key="transportation.id"
          >
            <b-col md="6">
              <TransportationSegmentCard
                v-if="
                  transportation.departures &&
                  transportation.departures.length > 0
                "
                transport-type="coach"
                direction="Départ"
                :transportation="transportation.departures"
              />
            </b-col>
            <b-col md="6">
              <TransportationSegmentCard
                v-if="
                  transportation.returns && transportation.returns.length > 0
                "
                transport-type="coach"
                direction="Retour"
                :transportation="transportation.returns"
              />
            </b-col>
          </b-row>
        </template>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" />
          <span>Prix</span>
        </template>

        <PricingCard :current-package="pkg" />
      </b-tab>
    </b-tabs>

    <NewPublicReservationModalVue
      :ref="`new-public-reservation-modal-${pkg.id}`"
      :agents="availableAgents"
      :current-agent-id="currentAgentId"
      @submit="$emit('book', { package_id: pkg.id, form_data: $event })"
    />
  </b-card>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BCarousel,
  BCarouselSlide,
  BBadge,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import TransportationSegmentCard from "./TransportationSegmentCard.vue";
import AccommodationCard from "./AccommodationCard.vue";
import PricingCard from "./PricingCard.vue";
import router from "@/router";

import { GroupReservationService } from "@/views/groups/services/GroupReservationService.js";
import { useToastNotification } from "@/shared/composables/use-toast-notifications";
import { GroupTransportationService } from "../../services/GroupTransportationService";
import NewPublicReservationModalVue from "@/views/groups/group/GroupSummary/NewPublicReservationModal.vue";

export const groupTransportationService = new GroupTransportationService();
export const groupReservationService = new GroupReservationService();

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BBadge,
    BListGroup,
    BListGroupItem,
    TransportationSegmentCard,
    AccommodationCard,
    PricingCard,
    NewPublicReservationModalVue,
  },
  props: {
    currentGroup: Object,
    groupId: String,
    pkg: Object,
    currentAccommodations: Array,
    isPackageUnavailable: Boolean,
    canReserved: Boolean,
    showReservationButton: Boolean,
    availableAgents: Array,
    currentAgentId: String,
    showAccommodationTab: {
      type: Boolean,
      default: true,
    },
    showTransportationTab: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const addNewReservation = async () => {
      const reservation = {
        insurance_contract_number: null,
        insurer_name: null,
        insurance_types: ["medical"],
        has_trip_insurance: false,
        has_membership: false,
        extra_information: "",
        wants_package_modification: true,
        membership_number: null,
        membership_company: null,
        wants_insurance_submission: false,
        seat_selection: null,
        status: "DRAFT",
      };

      try {
        const route = context.root.$route;
        const publicAccessToken = route.query.token;

        const response = await groupReservationService.postReservationAll(
          props.groupId,
          reservation,
          publicAccessToken
        );
        const match = response.headers.location.split("/");
        const reservation_id = match[7];

        let routeName = "group-edit-reservation";
        let queryParams = { package_id: props.pkg.id };

        if (publicAccessToken) {
          routeName = "group-reservation-public";
          queryParams.token = publicAccessToken;
        }

        router.push({
          name: routeName,
          params: { id: props.groupId, reservation_id: reservation_id },
          query: queryParams,
        });
      } catch (e) {
        displayErrorMessage("L'ajout a échoué");
        console.error(e);
      }
    };

    const { displaySuccessMessage, displayErrorMessage } =
      useToastNotification();
    return {
      addNewReservation,
      displaySuccessMessage,
      displayErrorMessage,
    };
  },
  data() {
    return {
      image: require("@/assets/images/hotels/rooms/hotel_1.jpg"),
      currentFlightTransportations: [],
      currentCoachTransportations: [],
    };
  },
  created: function () {
    this.currentFlightTransportations = this.currentGroup
      .has_flight_transportation
      ? this.pkg.transportations.filter((x) => x.type === "flight")
      : [];
    this.currentCoachTransportations = this.currentGroup
      .has_coach_transportation
      ? this.pkg.transportations.filter((x) => x.type === "coach")
      : [];
  },
  methods: {
    getDepartureCity(transportation) {
      return transportation.departures[0].departure_city;
    },
    getArrivalCity(transportation) {
      let returns = transportation.returns;
      let returnCount = returns.length;
      return returns[returnCount - 1]?.arrival_city;
    },
    getRoomTitle(room) {
      if (Object.hasOwn(room, "title")) {
        return room.title;
      }
      return this.getCurrentAccommodation(room.id)[0]
        ? this.getCurrentAccommodation(room.id)[0].title
        : "";
    },
    getCurrentAccommodation(id) {
      let currentAccommodation = this.currentAccommodations.filter(
        (accommodation) => {
          return accommodation.rooms.find((room) => room.id === id);
        }
      );
      return currentAccommodation;
    },
    bookPackage() {
      const publicAccessToken = this.$route.query.token;

      if (publicAccessToken) {
        this.$refs[
          `new-public-reservation-modal-${this.pkg.id}`
        ].$children[0].show();
        // }
      } else {
        this.addNewReservation();
      }
    },
    isRoomsEmpty(rooms) {
      try {
        return !rooms.length > 0;
      } catch {
        return true;
      }
    },
    getMainPricingUsingDoubleFirstStrategy() {
      let mainPricing = {
        price: "N/A",
        occupation: "N/A",
      };
      let found = false;

      ["DOUBLE", "SINGLE", "TRIPLE", "QUADRUPLE"].forEach((occupationType) => {
        if (found) {
          return;
        }

        this.pkg.occupation_types.forEach((occupation) => {
          if (found) {
            return;
          }

          if (occupation.type === occupationType) {
            const occupationPricing = occupation.occupation_type_pricing.find(
              (pricing) => {
                return pricing.type == "ADULT" && pricing.index == 0;
              }
            );

            if (occupationPricing) {
              found = true;

              mainPricing = {
                price: occupationPricing.price,
                occupation: occupationType,
              };
            }
          }
        });
      });

      return mainPricing;
    },
    getMainPricingUsingLowToHighStrategy() {
      let lowestPrice = null;
      let mainPricing = {
        price: "N/A",
        occupation: "N/A",
      };

      this.pkg.occupation_types.forEach((occupation) => {
        const occupationPricing = occupation.occupation_type_pricing.find(
          (pricing) => {
            return pricing.type == "ADULT" && pricing.index == 0;
          }
        );

        if (
          occupationPricing &&
          (lowestPrice == null || occupationPricing.price < lowestPrice)
        ) {
          lowestPrice = occupationPricing.price;

          mainPricing = {
            price: occupationPricing.price,
            occupation: occupation.type,
          };
        }
      });

      return mainPricing;
    },
  },
  computed: {
    occupationType() {
      let occupation_type = [];

      this.pkg.occupation_types.forEach((occupation) => {
        occupation_type.push(occupation.type);
      });

      return occupation_type;
    },
    getMainPricing() {
      try {
        let mainPricing = {};

        if (this.pkg.price_display_strategy) {
          switch (this.pkg.price_display_strategy) {
            case "double_first":
              mainPricing = this.getMainPricingUsingDoubleFirstStrategy();
              break;
            case "low_to_high":
              mainPricing = this.getMainPricingUsingLowToHighStrategy();
              break;
          }
        } else {
          mainPricing = this.getMainPricingUsingDoubleFirstStrategy();
        }

        return mainPricing;
      } catch (e) {
        console.error(e);
        return {
          price: "N/A",
          occupation: "N/A",
        };
      }
    },
  },
};
</script>

<style lang="scss">
.card-developer-meetup {
  .meetup-header {
    margin-bottom: 2rem;

    .meetup-day {
      text-align: center;
      border-right: 1px solid #d8d6de;
      padding-right: 1.3rem;
      margin-right: 1.3rem;
    }
  }
  .media {
    .avatar .avatar-content {
      width: 34px;
      height: 34px;
    }
    & + .media {
      margin-top: 1.2rem;
    }
  }
  .avatar-group {
    margin-top: 2rem;
  }
}
</style>
