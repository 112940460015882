var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"lg":"12","md":"12"}},[(!_vm.hasPackageReservations)?_c('div',{staticClass:"mt-4"},[_c('b-card',{staticClass:"mx-auto",staticStyle:{"max-width":"80%","min-width":"60%"},attrs:{"title":"Ajouter un forfait à votre réservation","header-tag":"header","body-class":"text-center"}},[_c('b-card-text',[_vm._v(" Sélection du forfait voyage et confirmation du nombre de voyageurs ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.showAddPackageModal}},[_vm._v("Débuter")])],1)],1):_c('b-card',{staticClass:"mt-2",attrs:{"title":"Votre réservation","header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',[_vm._v("Confirmation du forfait & chambre")]),_c('b-button',{attrs:{"variant":"outline-primary","disabled":!(
              _vm.currentReservation.status != 'SUBMITTED' ||
              _vm.canViewBasedOnRole(_vm.currentAuthUser, [
                _vm.Roles.ORGANIZATION_ADMIN.role,
                _vm.Roles.ORGANIZATION_COORDINATOR.role ])
            )},on:{"click":_vm.showAddPackageModal}},[_vm._v("Modifier")])]},proxy:true},{key:"footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$emit('nextTab')}}},[_vm._v("Suivant")])]},proxy:true}])},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('div',[_c('h5',{staticClass:"mb-75"},[_vm._v("Numéro de réservation")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.currentReservation.reservation_number)+" ")])],1)]),_c('b-col',{attrs:{"cols":"3"}},[_c('div',[_c('h5',{staticClass:"mb-75"},[_vm._v("Forfait sélectionné")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.currentReservation.package_reservations[0].group_package .name)+" ")])],1)]),_c('b-col',{attrs:{"cols":"3"}},[_c('div',[_c('h5',{staticClass:"mb-75"},[_vm._v("Nombre de chambre(s)")]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.currentReservation.package_reservations.length)+" ")])],1)])],1),_c('b-row',{staticClass:"mt-3"},_vm._l((_vm.currentReservation.package_reservations),function(room,index){return _c('b-col',{key:index,attrs:{"cols":"3"}},[_c('div',[_c('h5',{staticClass:"mb-75"},[_vm._v("Chambre #"+_vm._s(index + 1))]),_c('ul',{staticStyle:{"list-style":"none","padding-inline-start":"2px"}},[_vm._l((_vm.typeToNumberTravelers(
                        _vm.getRoomOccupationType(room)
                      )),function(traveler){return _c('li',{key:'adult-' + traveler},[_c('span',{staticClass:"bullet bullet-sm mr-1 bullet-primary"}),_c('small',{staticClass:"text-capitalize"},[_vm._v("Adulte")])])}),_vm._l((room.occupation_type.travelers_max -
                      _vm.typeToNumberTravelers(_vm.getRoomOccupationType(room))),function(traveler){return _c('li',{key:'kid-' + index + '-' + traveler},[_c('span',{staticClass:"bullet bullet-sm mr-1 bullet-primary"}),_c('small',{staticClass:"text-capitalize"},[_vm._v("Enfant (optionnel)")])])})],2)])])}),1)],1),(_vm.tenantConfig.paymentEnabled)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-row',[(_vm.hasPackageReservations)?_c('b-col',[_c('ReservationPricingCard',{attrs:{"current-reservation":_vm.currentReservation,"reservation-options":_vm.reservationOptions}})],1):_vm._e()],1)],1):_vm._e()],1)],1)]),(_vm.showModalWindow)?_c('GroupReservationPackageModal',{ref:"add-reservation-package-modal",attrs:{"available-boarding-points":_vm.availableBoardingPoints,"current-agent":_vm.currentAgent,"agents":_vm.agents,"show-boarding-points":_vm.currentGroup.has_coach_transportation,"current-reservation":_vm.currentReservation,"unavailable-packages":_vm.unavailablePackages,"current-packages":_vm.currentPackages},on:{"submitUpdateReservationPackage":_vm.submitUpdateReservationPackage,"submitReservationPackage":_vm.submitReservationPackage,"cancel":function($event){_vm.showModalWindow = false},"updateReservation":function($event){return _vm.$emit('updateReservation', $event)}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }