import axios from "@axios";

export class GroupPackagesService {
  constructor() {}

  async getPackages(group_id, publicAccessToken) {
    return new Promise((resolve, reject) => {
      let url = `/groups/${group_id}/packages`;

      if (publicAccessToken != null) {
        url = `/groups/${group_id}/packages?token=${publicAccessToken}`;
      }

      axios
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async getPackage(group_id, package_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/groups/${group_id}/packages/${package_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async deletePackage(group_id, package_id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/groups/${group_id}/packages/${package_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async createPackage(group_id, payload) {
    let IMAGE_DEFAULT_URL = require("@/assets/images/group/stock_plane_2.jpeg");
    return new Promise((resolve, reject) => {
      let body = {
        name: payload.name,
        reference_number: payload.reference_number,
        occupation_types: this.extractOccupationsList(
          payload.occupation_types
        ),
        room_ids: this.extractRoomIds(payload.room_ids),
        image_url: payload.image_url || IMAGE_DEFAULT_URL,
        media_id: payload.media_id,
        description: payload.name,
        accept_children: payload.accept_children,
        is_public: payload.is_public ?? true,
        transportation_ids: payload.transportations.map(x => x.id),
      };

      axios
        .post(`/groups/${group_id}/packages`, body)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }
  async updatePackage(group_id, package_id, payload) {
    const IMAGE_DEFAULT_URL =
      "https://nouvelles.paxeditions.com/storage/app/uploads/public/5f6/df9/124/5f6df9124e7da304146062.jpg";

    return new Promise((resolve, reject) => {
      let body = {
        name: payload.name,
        reference_number: payload.reference_number,
        occupation_types: this.extractOccupationsList(
          payload.occupation_types
        ),
        room_ids: this.extractRoomIds(payload.room_ids),
        image_url: payload.image_url || IMAGE_DEFAULT_URL,
        description: payload.name,
        accept_children: payload.accept_children,
        is_public: payload.is_public,
        media_id: payload.media_id ?? null,
        transportation_ids: payload.transportations.map(x => x.id),
      };

      axios
        .put(`/groups/${group_id}/packages/${package_id}`, body)
        .then(async (response) => {
          let pkg = await this.getPackage(group_id, package_id);
          if (!body.accept_children) {
            pkg.data.occupation_types.forEach((element) => {
              element.supported_children_types = [];
            });
            await this.addPackageRules(group_id, package_id, pkg.data);
          }
          resolve(response);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response);
          } else {
            reject(error);
          }
        });
    });
  }

  async syncPackageOrdering(group_id, packageIds) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/groups/${group_id}/packages/ordering`, {
          new_ordering: packageIds,
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  async addPackageRules(group_id, package_id, package_info) {
    return new Promise((resolve, reject) => {
      let allPromises = [];

      axios
        .get(`/groups/${group_id}/packages/${package_id}`)
        .then((response) => {
          response.data.occupation_types.forEach((occupation) => {
            let url = `groups/${group_id}/packages/${package_id}/occupation-types/${occupation.id}/rules`;

            let occupationWithRules = this.findOccupationInArray(
              package_info.occupation_types,
              occupation.type
            );
            if (occupationWithRules.adult_min !== undefined) {
              let body = {
                adult_min: occupationWithRules.adult_min,
                travelers_max: occupationWithRules.travelers_max,
                supported_children_types:
                  occupationWithRules.supported_children_types || [],
              };

              allPromises.push(axios.put(url, body));
            }
          });

          Promise.all(allPromises)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              if (error.response) {
                reject(error.response);
              } else {
                reject(error);
              }
            });
        });
    });
  }

  async addPackagePricing(group_id, package_id, package_info) {
    return new Promise((resolve, reject) => {
      let allPromises = [];

      axios
        .get(`/groups/${group_id}/packages/${package_id}`)
        .then((response) => {
          response.data.occupation_types.forEach((occupation) => {
            let url = `groups/${group_id}/packages/${package_id}/occupation-types/${occupation.id}/pricing`;

            let occupationWithRules = this.findOccupationInArray(
              package_info.occupation_types,
              occupation.type
            );

            let body = occupationWithRules.occupation_type_pricing;
            allPromises.push(axios.put(url, body));
          });

          Promise.all(allPromises)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              if (error.response) {
                reject(error.response);
              } else {
                reject(error);
              }
            });
        });
    });
  }

  extractRoomIds(rooms) {
    let room_ids = [];
    rooms.forEach((room) => {
      room_ids.push(room.accommodation);
    });
    return room_ids;
  }

  extractOccupationsList(occupations) {
    let occupation_types = [];
    occupations.forEach((occupation) => {
      occupation_types.push(occupation.type.toUpperCase());
    });
    return occupation_types;
  }

  formatPricingBody(pricing, rules) {
    let body = [];

    pricing.adults.forEach((price, index) => {
      body.push({
        type: "ADULT",
        index,
        price,
      });
    });

    rules.children_categories.forEach((children_category) => {
      pricing[children_category].forEach((price, index) => {
        body.push({
          type: children_category.toUpperCase(),
          index,
          price,
        });
      });
    });

    return body;
  }

  findOccupationInArray(occupations, type) {
    return occupations.find((occupation) => {
      return occupation.type.toUpperCase() === type.toUpperCase();
    });
  }
}
