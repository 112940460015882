<template>
  <b-row class="match-height">
    <b-col lg="12" md="12">
      <div id="load-script"></div>
      <div class="mt-4">
        <b-card id="paymentCard" class="pt-0">
          <template #header>
            <h3>Paiement de la réservation</h3>
          </template>
          <b-row>
            <b-col v-if="currentReservation.status !== 'SUBMITTED'" md="6">
              <div v-if="paymentState === 'initial'">
                <b-card>
                  <b-button
                    class="font-weight-normal text-left mb-2"
                    :pressed="selectedPaymentMethod === 'one'"
                    @click="selectedPaymentMethod = 'one'"
                  >
                    <selection-circle
                      :active="selectedPaymentMethod === 'one'"
                      class="mr-1"
                    />

                    Payer avec une seule carte de crédit
                  </b-button>
                  <b-button
                    class="font-weight-normal text-left mb-2"
                    :pressed="selectedPaymentMethod === 'many'"
                    @click="selectedPaymentMethod = 'many'"
                  >
                    <selection-circle
                      :active="selectedPaymentMethod === 'many'"
                      class="mr-1"
                    />
                    Payer avec plusieurs cartes de crédit
                  </b-button>
                  <b-button
                    class="font-weight-normal text-left mb-2"
                    :pressed="selectedPaymentMethod === 'none'"
                    @click="selectedPaymentMethod = 'none'"
                  >
                    <selection-circle
                      :active="selectedPaymentMethod === 'none'"
                      class="mr-1"
                    />
                    Je préfère prendre contact avec l'agence pour procéder au
                    paiement
                  </b-button>

                  <b-row align-h="end">
                    <b-col cols="2">
                      <b-button size="sm" @click="configurePayments()"
                        >Suivant</b-button
                      >
                    </b-col>
                  </b-row>
                </b-card>
              </div>

              <div v-if="paymentState === 'paymentSplit'">
                <b-card>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col" class="text-center">Montant</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(splitPayment, i) in orderedSplitPayments"
                        :key="i"
                      >
                        <th scope="row">Carte #{{ i + 1 }}</th>
                        <td v-if="paymentSplitEdit && !splitPayment.paid">
                          <b-input-group append="$">
                            <b-form-input v-model="splitPayment.amount" />
                          </b-input-group>
                        </td>
                        <td v-else class="text-center">
                          {{ splitPayment.amount }} $
                        </td>
                        <td v-if="paymentSplitEdit && !splitPayment.paid">
                          <a
                            v-if="i > 0"
                            href="#"
                            @click.prevent="removePaymentCard(i)"
                            >Retirer la carte</a
                          >
                        </td>
                        <td v-else>
                          <b-button
                            v-if="splitPayment.paid"
                            size="sm"
                            variant="success"
                            :disabled="splitPayment.paid"
                          >
                            <feather-icon icon="CheckCircleIcon" class="mr-1" />
                            Paiement complété
                          </b-button>
                          <b-button
                            v-else
                            size="sm"
                            variant="primary"
                            @click="createPayment(splitPayment)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="18"
                              width="18"
                              viewBox="0 0 576 512"
                              class="mr-1"
                            >
                              <path
                                fill="#ffffff"
                                d="M64 32C28.7 32 0 60.7 0 96l0 32 576 0 0-32c0-35.3-28.7-64-64-64L64 32zM576 224L0 224 0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-192zM112 352l64 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-64 0c-8.8 0-16-7.2-16-16s7.2-16 16-16zm112 16c0-8.8 7.2-16 16-16l128 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-128 0c-8.8 0-16-7.2-16-16z"
                              />
                            </svg>
                            Effectuer le paiement
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody>
                      <tr class="font-weight-bold">
                        <td>
                          {{
                            paymentSplitEdit
                              ? "Montant restant à diviser"
                              : "Balance restante"
                          }}
                        </td>
                        <td class="text-center">
                          <span
                            v-if="paymentSplitEdit"
                            :class="
                              remainingBalanceToSplit != 0.0
                                ? 'text-danger'
                                : ''
                            "
                            >{{ remainingBalanceToSplit }} $</span
                          >
                          <span v-else>{{ remainingBalanceToPay }} $</span>
                        </td>
                        <td>
                          <a
                            v-if="paymentSplitEdit"
                            href="#"
                            @click.prevent="addPaymentCard"
                            >Ajouter une carte</a
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <b-row align-h="end">
                    <b-col cols="5" class="text-right mr-1">
                      <a
                        href="#"
                        v-if="!paymentSplitEdit"
                        @click.prevent="paymentSplitEdit = !paymentSplitEdit"
                        >Modifier la répartition des paiements</a
                      >
                      <b-button
                        v-else
                        variant="success"
                        size="sm"
                        :disabled="
                          paymentSplitEdit && remainingBalanceToSplit != 0.0
                        "
                        @click="endPaymentSplitEdition"
                      >
                        Confirmer la répartition
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card>
              </div>

              <div v-if="paymentState === 'checkout'">
                <b-card>
                  <div id="moneris-form">
                    <div id="monerisCheckout"></div>
                  </div>
                </b-card>
              </div>

              <div v-if="paymentState === 'paymentDone'">
                <b-card-text>
                  Votre réservation est prête à être soumise.
                </b-card-text>

                <b-button
                  variant="success"
                  class="mr-1"
                  @click="submitReservation"
                  :disabled="loading"
                >
                  Confirmer
                </b-button>
              </div>
            </b-col>

            <b-col md="6" v-else-if="paymentsMade.value">
              <ReservationPaymentsCard
                :current-reservation="currentReservation"
                v-if="paymentsMade.value"
              />
            </b-col>

            <b-col md="6" v-if="currentReservation">
              <ReservationPricingCard
                :reservation-options="reservationOptions"
                :current-reservation="currentReservation"
              />
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormRadio,
  BInputGroup,
} from "bootstrap-vue";
import SelectionCircle from "@/@core/components/selection-circle/SelectionCircle.vue";
import { useReservationApi } from "@/modules/reservation/composables/use-reservation-api";
import { onMounted, ref, watch, computed } from "@vue/composition-api";
import ReservationPricingCard from "@/views/groups/group/GroupReservations/EditReservation/EditPayment/ReservationPricingCard.vue";
import ReservationPaymentsCard from "@/views/groups/group/GroupReservations/EditReservation/EditPayment/ReservationPaymentsCard.vue";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import router from "@/router";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { pricingUtils } from "@/shared/utils/pricing-utils";
import { GroupReservationService } from "@/views/groups/services/GroupReservationService";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BForm,
    BFormInput,
    BFormCheckbox,
    ReservationPricingCard,
    BFormGroup,
    ReservationPaymentsCard,
    BFormRadio,
    FeatherIcon,
    BInputGroup,
    SelectionCircle,
  },
  props: [
    "currentPackages",
    "currentReservation",
    "currentAgent",
    "errors",
    "currentGroupId",
    "reservationOptions",
  ],

  setup(props, ctx) {
    const {
      getPaymentDue,
      getMonerisCheckoutTicket,
      getMonerisCheckoutReceipt,
      getPaymentAmounts,
      updatePaymentAmounts,
    } = useReservationApi();
    const { formatPaymentDueForDeposit } = pricingUtils();
    const { store } = useApplicationContext();
    const groupReservationService = new GroupReservationService();

    const publicAccessToken = ctx.root.$route.query.token;

    let route = ctx.root.$route;
    let payment_processor = null;
    let moneris_checkout_ticket_id = null;
    let monerisCheck = null;

    let selectedPaymentMethod = ref("one");
    let paymentCompleted = ref(false);
    let showPaymentForm = ref(false);
    let paymentsMade = ref(false);
    let paymentSkip = ref(false);
    let message = ref("No Message");
    let paymentSplitEdit = ref(false);

    let paymentState = ref("initial");
    let duePayments = ref({});
    let depositsTotal = ref(0.0);
    let splitPayments = ref([]);

    let loading = ref(false);

    watch(paymentState, async (val) => {
      if (val === "paymentSplit") {
        await retrieveDuePayments();
      }
    });

    watch(selectedPaymentMethod, (val) => {
      if (val === "none") {
        paymentSkip.value = true;
      } else {
        paymentSkip.value = false;
      }
    });

    const retrieveDuePayments = async () => {
      let paymentsDue = await getPaymentDue(
        props.currentReservation.group_id,
        props.currentReservation.id,
        publicAccessToken
      );

      duePayments.value = formatPaymentDueForDeposit(paymentsDue.data);
      depositsTotal.value = duePayments.value.deposits.reduce(
        (partialSum, x) => partialSum + x.amount,
        0
      );

      const amountsResponse = await getPaymentAmounts(
        props.currentReservation.group_id,
        props.currentReservation.id,
        publicAccessToken
      );

      const amounts = amountsResponse.data;

      if (amounts.length > 0) {
        selectedPaymentMethod.value = amounts.length > 1 ? "many" : "one";

        splitPayments.value.splice(0, splitPayments.value.length);
        amounts.forEach((amount) => {
          splitPayments.value.push({
            id: amount.id,
            paid: amount.paid_on != null,
            amount: parseFloat(amount.amount / 100),
          });
        });

        if (
          amounts
            .filter((x) => x.paid)
            .reduce((partialSum, x) => partialSum + x.amount / 100, 0) ===
          depositsTotal.value
        ) {
          paymentState.value = "paymentDone";
        } else {
          paymentState.value = "paymentSplit";
        }
      }
    };

    const remainingBalanceToSplit = computed(() => {
      return splitPayments.value
        .reduce((partialSum, x) => partialSum - x.amount, depositsTotal.value)
        .toFixed(2);
    });

    const remainingBalanceToPay = computed(() => {
      return splitPayments.value
        .reduce(
          (partialSum, x) => partialSum - (x.paid ? x.amount : 0),
          depositsTotal.value
        )
        .toFixed(2);
    });

    const orderedSplitPayments = computed(() => {
      return splitPayments.value.sort((a, b) => {
        if (a.paid && !b.paid) {
          return -1;
        } else if (!a.paid && b.paid) {
          return 1;
        } else if (a.paid && b.paid) {
          return new Date(a.paid_on) - new Date(b.paid_on);
        } else {
          return a.display_order - b.display_order;
        }
      });
    });

    const configurePayments = async () => {
      if (selectedPaymentMethod.value === "one") {
        splitPayments.value.push({
          id: null,
          paid: false,
          amount: depositsTotal.value,
        });

        paymentState.value = "paymentSplit";
      } else if (selectedPaymentMethod.value === "many") {
        const splittedAmounts = splitAmount(depositsTotal.value);
        splittedAmounts.forEach((deposit) => {
          splitPayments.value.push({
            paid: false,
            amount: deposit,
          });
        });

        paymentSplitEdit.value = true;
        paymentState.value = "paymentSplit";
      } else {
        paymentState.value = "paymentDone";
      }

      endPaymentSplitEdition();
    };

    const addPaymentCard = () => {
      splitPayments.value.push({
        paid: false,
        amount: 0.0,
      });
    };
    const removePaymentCard = (cardIndex) => {
      if (cardIndex === 0) {
        return;
      }

      splitPayments.value.splice(cardIndex, 1);
    };

    const splitAmount = (amount) => {
      let scaledAmount = amount * 100;
      let firstAmount = Math.floor(scaledAmount / 2);
      let secondAmount = Math.ceil(scaledAmount / 2);

      firstAmount = firstAmount / 100;
      secondAmount = secondAmount / 100;

      if (firstAmount + secondAmount !== amount) {
        secondAmount = amount - firstAmount;
      }

      return [firstAmount, secondAmount];
    };

    const endPaymentSplitEdition = async () => {
      splitPayments.value = splitPayments.value.filter((x) => x.amount != 0.0);

      const amounts = splitPayments.value
        .filter((x) => !x.paid)
        .map((x) => {
          return {
            amount: x.amount * 100,
          };
        });

      await updatePaymentAmounts(
        props.currentReservation.group_id,
        props.currentReservation.id,
        amounts,
        publicAccessToken
      );
      await retrieveDuePayments();

      paymentSplitEdit.value = false;
    };

    async function createPayment(payment) {
      showPaymentForm.value = true;
      paymentState.value = "checkout";

      try {
        initializeMoneris(payment.id);
      } catch (error) {
        console.error("error while initializing payment solution", error);
      }
    }

    const initializeMoneris = async (paymentId) => {
      if (showPaymentForm.value) {
        monerisCheck = new monerisCheckout();

        let monerisResponse = await getMonerisCheckoutTicket(
          props.currentReservation,
          paymentId,
          publicAccessToken
        );

        if (!monerisResponse || monerisResponse.data.success === "false") {
          showPaymentForm.value = false;

          ctx.root.$swal({
            icon: "warning",
            title: "Paiement non disponible",
            text: `Une erreur s'est produite lors de la connexion à la plateforme de paiement. Veuillez réessayer plus tard.`,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        } else {
          const monerisEnvironment =
            process.env.VUE_APP_MONERIS_ENVIRONMENT || "qa";
          monerisCheck.setMode(monerisEnvironment);
          monerisCheck.setCheckoutDiv("monerisCheckout");
          moneris_checkout_ticket_id = monerisResponse.data.response.ticket;
          monerisCheck.startCheckout(monerisResponse.data.response.ticket);

          monerisCheck.setCallback("page_loaded", (event) => {
            console.log("Moneris page_loaded", event);
          });

          monerisCheck.setCallback("cancel_transaction", (error) => {
            console.log("Moneris cancel_transaction", error, monerisCheck);
            monerisCheck.closeCheckout(" ");
            document.querySelector("#monerisCheckout-Frame").remove();
            showPaymentForm.value = false;
            paymentState.value = "paymentSplit";
          });

          monerisCheck.setCallback("error_event", (error) => {
            console.error("Moneris error_event", error);
            monerisCheck.closeCheckout(" ");
            showPaymentForm.value = false;
          });

          monerisCheck.setCallback("payment_receipt", (data) => {
            console.log("Moneris payment_receipt", data);
            getMonerisReceipt(publicAccessToken);
          });
        }
      }
    };

    async function getMonerisReceipt(accessToken) {
      monerisCheck.closeCheckout(" ");
      showPaymentForm.value = false;

      await getMonerisCheckoutReceipt(
        route.params.id,
        route.params.reservation_id,
        moneris_checkout_ticket_id,
        accessToken
      );

      await retrieveDuePayments();

      if (
        splitPayments.value.filter((x) => x.paid).length ===
        splitPayments.value.length
      ) {
        submitReservation();
      }
    }

    onMounted(() => {
      retrieveDuePayments();
    });

    paymentsMade.value = computed(() => {
      if (props.currentReservation.reservation_payments) {
        return props.currentReservation.reservation_payments.length >= 1;
      } else {
        return false;
      }
    });

    const submitReservation = async () => {
      loading.value = true;
      await groupReservationService.updateStatus(
        props.currentReservation.group_id,
        props.currentReservation.id,
        {
          status: "SUBMITTED",
        },
        publicAccessToken
      );
      ctx.emit("submit");
    };

    const handleSubmit = async (e) => {
      if (paymentSkip.value) {
        if (publicAccessToken) {
          const url = `${window.location.origin}/public/groups/${route.params.id}/reservation/${route.params.reservation_id}/confirmation?token=${publicAccessToken}`;
          window.location.href = url;
        } else {
          router.push({ path: `/groups/${route.params.id}/reservations` });
        }
        return;
      }

      let baseURL = window.location.origin;
      let returnUrl = "";

      if (publicAccessToken) {
        returnUrl = `${baseURL}/public/groups/${route.params.id}/reservation/${route.params.reservation_id}/confirmation?monerisTicket=${moneris_checkout_ticket_id}&token=${publicAccessToken}`;
      } else {
        returnUrl = `${baseURL}/groups/${route.params.id}/reservation/${route.params.reservation_id}/confirmation?monerisTicket=${moneris_checkout_ticket_id}`;
      }

      window.location.href = returnUrl;
    };

    return {
      depositsTotal,
      handleSubmit,
      submitReservation,
      paymentCompleted,
      message,
      showPaymentForm,
      paymentsMade,
      configurePayments,
      createPayment,
      paymentSkip,
      payment_processor,
      selectedPaymentMethod,
      paymentState,
      splitPayments,
      orderedSplitPayments,
      paymentSplitEdit,
      remainingBalanceToSplit,
      remainingBalanceToPay,
      addPaymentCard,
      removePaymentCard,
      endPaymentSplitEdition,
      loading,
      splitAmount,
    };
  },
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

.link {
  color: #015a9c;
  text-decoration: none;
  cursor: pointer;
}

#payment-button {
  padding: 3em;
  width: 80%;
  margin: auto;
}

.payment-checkbox {
  width: 80%;
  margin: auto;
}

#moneris-form {
  border: 1px solid #efefef;
  height: 30rem;
  min-height: 750px;
}

button {
  background: none;
}

.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.btn-sm {
  font-size: 12px;
}

#paymentCard .card-body {
  padding-top: 0 !important;
}
</style>
