<template>
  <b-row class="match-height">
    <b-col lg="12" md="12">
      <div id="load-script"></div>
      <div class="mt-4">
        <b-card>
          <template #header>
            <h3>Paiement de la réservation</h3>
          </template>
          <b-row>
            <b-col md="6" v-if="showPaymentForm">
              <div v-if="payment_processor == 'stripe'">
                <!-- Stripe -->
                <b-card id="payment-form">
                  <div id="link-authentication-element">
                    <!--Stripe.js injects the Link Authentication Element-->
                  </div>
                  <div id="payment-element">
                    <!--Stripe.js injects the Payment Element-->
                  </div>

                  <div id="payment-message" class="hidden"></div>

                  <button
                    id="submit"
                    @click="handleSubmit"
                    class="pay-stripe"
                    v-if="currentReservation.status == 'SUBMITTED'"
                  >
                    <div class="spinner hidden" id="spinner"></div>
                    <span id="button-text">Pay now</span>
                  </button>
                </b-card>
              </div>
            </b-col>

            <b-col md="6" v-else-if="isSecondPayment.value">
              <ReservationPaymentsCard
                :current-reservation="currentReservation"
                v-if="isSecondPayment.value"
              />
            </b-col>
            <b-col md="6" v-else>
              <b-button
                id="payment-button"
                variant="success"
                class="link"
                @click="createPayment"
              >
                <feather-icon icon="CardIcon" size="24" />
                <div>
                  <font-awesome-icon
                    icon="fa-solid fa-credit-card"
                    class="mr-1 fa-2x"
                  />
                  <span>Procéder au paiement</span>
                </div>
              </b-button>
              <div class="payment-checkbox mt-1">
                <b-form-group
                  label="Je préfère prendre contact avec l'agence pour procéder au paiement"
                  label-cols="10"
                >
                  <b-form-checkbox
                    class="mr-0 mt-50"
                    v-model="paymentSkip"
                    inline
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col md="6" v-if="currentReservation">
              <ReservationPricingCard
                :reservation-options="reservationOptions"
                :current-reservation="currentReservation"
              />
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BForm,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import { useReservationApi } from "@/modules/reservation/composables/use-reservation-api";
import { onMounted, ref, watch, computed } from "@vue/composition-api";
import ReservationPricingCard from "@/views/groups/group/GroupReservations/EditReservation/EditPayment/ReservationPricingCard.vue";
import ReservationPaymentsCard from "@/views/groups/group/GroupReservations/EditReservation/EditPayment/ReservationPaymentsCard.vue";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { AUTH_STORE_NAMESPACE } from "@/modules/authnz/models/store";
import router from "@/router";
import { StripeElementCard } from "@vue-stripe/vue-stripe";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    StripeElementCard,
    BForm,
    BFormCheckbox,
    ReservationPricingCard,
    BFormGroup,
    ReservationPaymentsCard,
  },
  props: [
    "currentPackages",
    "currentReservation",
    "currentAgent",
    "errors",
    "currentGroupId",
    "reservationOptions",
  ],

  setup(props, ctx) {
    const { createPaymentIntent, getPaymentDue, getFICAVForReservation } =
      useReservationApi();
    const { store } = useApplicationContext();
    const publicAccessToken = ctx.root.$route.query.token;

    let route = ctx.root.$route;
    let stripe_key = null;
    let payment_processor = null;
    let paymentCompleted = ref(false);
    let showPaymentForm = ref(false);
    let isSecondPayment = ref(false);
    let paymentSkip = ref(false);

    let message = ref("No Message");
    let elements;

    try {
      let orgSettings = store.state[AUTH_STORE_NAMESPACE].orgSettings;

      if (orgSettings) {
        stripe_key = orgSettings.stripe_publishable_key ?? "";
        payment_processor = orgSettings.payment_processor;
      }
    } catch (e) {
      console.error("No Stripe Key Configured", e);
    }

    const stripe = ref(null);

    watch(paymentSkip, (val) => {
      if (!showPaymentForm.value) {
        enablePaymentButton(val);
      }
    });

    const enablePaymentButton = (value) => {
      ctx.emit("enablePaymentButton", value);
    };
    async function createPayment() {
      showPaymentForm.value = true;

      try {
        if (payment_processor == "stripe") {
          enablePaymentButton(true);
          initializeStripe();
        }
      } catch (error) {
        console.error("error while initializing payment solution", error);
      }
    }

    const initializeStripe = async () => {
      stripe.value = Stripe(stripe_key);

      if (showPaymentForm.value) {
        let amount = await calculateAmountToCharge();

        const appearance = {
          theme: "stripe",
        };

        const response = await createPaymentIntent(
          {
            amount,
            reservationId: props.currentReservation.reservation_number,
          },
          publicAccessToken
        );

        const clientSecret = response.data.client_secret;

        showPaymentForm.value = true;

        elements = stripe.value.elements({ appearance, clientSecret });

        const linkAuthenticationElement = elements.create("linkAuthentication");
        linkAuthenticationElement.mount("#link-authentication-element");

        linkAuthenticationElement.on("change", (event) => {
          emailAddress = event.value.email;
        });

        const paymentElementOptions = {
          layout: "tabs",
        };

        const paymentElement = elements.create(
          "payment",
          paymentElementOptions
        );
        paymentElement.mount("#payment-element");
      }
    };

    async function calculateAmountToCharge() {
      let paymentsDue = await getPaymentDue(
        props.currentReservation.group_id,
        props.currentReservation.id,
        publicAccessToken
      );
      let FICAVdue = await getFICAVForReservation(
        props.currentReservation.group_id,
        props.currentReservation.id,
        publicAccessToken
      );

      let amount = 0;

      paymentsDue.data.forEach((paymentDue) => {
        amount = amount + parseFloat(paymentDue.amount);
      });

      amount = parseFloat(amount) + parseFloat(FICAVdue.data.toFixed(2));
      return amount * 100;
    }

    async function checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      if (!clientSecret) {
        return;
      }

      const { paymentIntent } = await stripe.value.retrievePaymentIntent(
        clientSecret
      );

      switch (paymentIntent.status) {
        case "succeeded":
          message.value = "Payment succeeded!";
          break;
        case "processing":
          message.value = "Your payment is processing.";
          break;
        case "requires_payment_method":
          message.value = "Your payment was not successful, please try again.";
          break;
        default:
          message.value = "Something went wrong.";
          break;
      }
    }

    onMounted(() => {
      if (payment_processor == "stripe") {
        initializeStripe();
      }

      checkStatus();
    });

    isSecondPayment.value = computed(() => {
      if (props.currentReservation.reservation_payments) {
        return props.currentReservation.reservation_payments.length >= 1;
      } else {
        return false;
      }
    });

    const handleSubmit = async (e) => {
      if (paymentSkip.value) {
        if (publicAccessToken) {
          const url = `${window.location.origin}/public/groups/${route.params.id}/reservation/${route.params.reservation_id}/confirmation?token=${publicAccessToken}`;
          window.location.href = url;
        } else {
          router.push({ path: `/groups/${route.params.id}/reservations` });
        }
        return;
      }

      if (payment_processor === "stripe" && (!stripe || !elements)) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        console.log(
          "Stripe.js has not yet loaded.",
          "Make sure to disable form submission until Stripe.js has loaded."
        );

        return;
      }

      //setIsLoading(true);
      let baseURL = window.location.origin;
      let returnUrl = "";

      if (publicAccessToken) {
        returnUrl = `${baseURL}/public/groups/${route.params.id}/reservation/${route.params.reservation_id}/confirmation?token=${publicAccessToken}`;
      } else {
        returnUrl = `${baseURL}/groups/${route.params.id}/reservation/${route.params.reservation_id}/confirmation`;
      }

      if (payment_processor === "stripe") {
        const { error } = await stripe.value.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: returnUrl,
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          message = error.message;
        } else {
          message = "An unexpected error occurred.";
        }

        return error;
      }
    };

    return {
      handleSubmit,
      paymentCompleted,
      message,
      showPaymentForm,
      isSecondPayment,
      createPayment,
      paymentSkip,
      payment_processor,
    };
  },
};
</script>

<style scoped>
body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.hidden {
  display: none;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}
.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}
.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}

.link {
  color: #015a9c;
  text-decoration: none;
  cursor: pointer;
}

#payment-button {
  padding: 3em;
  width: 80%;
  margin: auto;
}

.payment-checkbox {
  width: 80%;
  margin: auto;
}

.pay-stripe {
  text-align: center;
  border: 1px solid transparent;
  padding: 0.786rem 1.5rem;
  border-radius: 0.358rem;
  border-color: #0dbff2 !important;
  background-color: #0dbff2 !important;
}

button {
  background: none;
}
</style>
