<template>
  <b-row>
    <b-col cols="12">
      <b-list-group
        class="mt-2"
        v-for="section in reservationOptions"
        :key="section.id"
      >
        <b-list-group-item class="bg-primary text-white">
          {{ section.title }}
        </b-list-group-item>

        <div v-for="question in section.questions" :key="question.id">
          <b-list-group-item>{{ question.title }}</b-list-group-item>
          <template v-if="question.answers.length > 0">
            <template v-if="question.is_traveler_specific">
              <b-list-group-item
                v-for="answer in question.answers"
                :key="answer.id"
                class="d-flex flex-row justify-content-between"
              >
                <span class="pl-2 font-weight-bold">{{
                  getTravelerName(answer)
                }}</span>
                {{ getAnswerText(answer, question) }}
              </b-list-group-item>
            </template>
            <template v-else>
              <b-list-group-item
                v-for="answer in question.answers"
                :key="answer.id"
                class="d-flex flex-row justify-content-end"
              >
                <div>{{ getAnswerText(answer, question) }}</div>
              </b-list-group-item>
            </template>
          </template>
          <b-list-group-item v-else class="d-flex flex-row justify-content-end">
            N/D
          </b-list-group-item>
        </div>
      </b-list-group>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BListGroup, BListGroupItem } from "bootstrap-vue";

export default {
  name: "ReservationOptionsList",
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  props: ["currentReservation", "reservationOptions"],
  computed: {
    travelers() {
      return this.currentReservation.package_reservations
        .map((x) => x.travelers)
        .flat();
    },
  },
  methods: {
    getAnswerPrice(price) {
      let value = 0.0;
      if (price) {
        value = price / 100;
      }
      return value.toFixed(2);
    },
    getAnswerText(answer, question) {
      let text = null;
      if (question.choice_type === "text") {
        text = answer.text_value;
      } else {
        let choice = question.choices.find((x) => x.id === answer.choice_id);
        text = choice.title;
        if (choice.price) {
          text += ` (${this.getAnswerPrice(choice.price)} $)`;
        }
      }

      return text ? text : "N/D";
    },
    getTravelerName(answer) {
      const traveler = this.travelers.find((x) => x.id === answer.traveler_id);
      return traveler.first_name + " " + traveler.last_name;
    },
  },
};
</script>
