<template>
  <div>
    <b-list-group>
      <b-list-group-item class="dark-line-item">
        Détails du prix (taxes incluses)
      </b-list-group-item>

      <div v-for="(room, index) in getRoomsPricings" :key="index">
        <b-list-group-item> Chambre #{{ index + 1 }} </b-list-group-item>
        <b-list-group-item v-for="pax in room" :key="pax.index">
          {{ t(`reservations['${pax.type}']`) }} #{{ pax.index + 1 }}
          <span style="float: right">${{ pax.price }}</span>
        </b-list-group-item>
      </div>
    </b-list-group>

    <b-list-group v-if="showReservationOptions" class="mt-1">
      <b-list-group-item class="dark-line-item">
        Options supplémentaires (taxes incluses)
      </b-list-group-item>
      <div v-if="travelers.length > 0">
        <div v-for="(traveler, index) in travelers" :key="index">
          <b-list-group-item>
            {{ traveler.first_name }} {{ traveler.last_name }}
            <span style="float: right">{{
              getReservationOptionsPriceForTraveler(traveler.id)
            }}</span>
          </b-list-group-item>
        </div>
      </div>
      <b-list-group-item
        v-if="
          !currentReservation.has_reservation_options || travelers.length == 0
        "
      >
        N/D
      </b-list-group-item>
    </b-list-group>
    <b-list-group class="mt-1">
      <b-list-group-item class="dark-line-item"> Dépôts </b-list-group-item>
      <template
        v-if="detailledDeposits.value && detailledDeposits.value.deposits"
      >
        <b-list-group-item
          v-for="paxDeposit in detailledDeposits.value.deposits"
          :key="paxDeposit.index"
        >
          {{ paxDeposit.travelerName }} ({{
            t(`reservations['${paxDeposit.type}']`)
          }})
          <span style="float: right">${{ paxDeposit.amount }}</span>
        </b-list-group-item>
      </template>
      <b-list-group-item>
        FICAV
        <span style="float: right"
          >${{
            FICAVamount.value ? FICAVamount.value.toFixed(2) : "0.00"
          }}</span
        >
      </b-list-group-item>
      <b-list-group-item v-if="detailledDeposits.value">
        Total - Payable aujourd'hui
        <span style="float: right"
          >${{
            (detailledDeposits.value.totalAmount + FICAVamount.value).toFixed(2)
          }}</span
        >
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import { pricingUtils } from "@/shared/utils/pricing-utils";
import { reactive, ref, computed, watch } from "@vue/composition-api";
import { useReservationApi } from "@/modules/reservation/composables/use-reservation-api";
import { useApplicationContext } from "@/shared/composables/use-application-context";
import { useLocalisation } from "@/shared/composables/use-localisation";
import { AUTH_STORE_NAMESPACE } from "@/modules/authnz/models/store";

import { GroupAdditionalOptionService } from "@/views/groups/services/GroupAdditionalOptionService.js";
export const additionalOptionService = new GroupAdditionalOptionService();

import store from "@/store/index";

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton,
    BListGroup,
    BListGroupItem,
  },
  props: ["currentReservation", "currentGroup", "reservationOptions"],
  data() {
    return {
      hasBasicInfo: false,
      currentReservationPackage: {},
      agents: [],
    };
  },
  setup(props, context) {
    const { t } = useLocalisation();
    const { getRoomPricingsFromReservation, formatPaymentDueForDeposit } =
      pricingUtils();

    const { getPaymentDue, getFICAVForReservation } = useReservationApi();
    const { forceUpdate } = useApplicationContext();
    const publicAccessToken = context.root.$route.query.token;
    let showAdditionalOptions = ref(false);
    let amount = reactive({});
    let FICAVamount = reactive({});
    let detailledDeposits = reactive({});
    let reservationOptionsPrices = ref([]);

    const getRoomsPricings = computed(() => {
      return getRoomPricingsFromReservation(props.currentReservation);
    });

    const travelers = computed(() => {
      return props.currentReservation.package_reservations
        .map((x) => {
          return x.travelers.length > 0 ? x.travelers : [];
        })
        .flat();
    });

    try {
      showAdditionalOptions.value =
        store.state[AUTH_STORE_NAMESPACE].orgSettings.additional_options_enable;
    } catch (e) {
      console.error(e);
    }

    const getDepositToPay = async () => {
      let paymentsDue = await getPaymentDue(
        props.currentReservation.group_id,
        props.currentReservation.id,
        publicAccessToken
      );

      detailledDeposits.value = formatPaymentDueForDeposit(paymentsDue.data);
      amount.value = paymentsDue.data;
      forceUpdate();
    };

    const getReservationOptionsPrices = async () => {
      if (
        props.currentReservation &&
        props.currentReservation.has_reservation_options
      ) {
        try {
          const { data } =
            await additionalOptionService.getReservationOptionsPricesByTravelers(
              props.currentReservation.group_id,
              props.currentReservation.id
            );
          reservationOptionsPrices.value = data;
          forceUpdate();
        } catch (e) {
          console.error(e);
          displayErrorMessage(
            "Une erreur s'est produite en essayant de retrouver les informations des options supplémentaires de la réservation."
          );
        }
      }
    };

    const getFICAVToPay = async () => {
      let FICAVdue = await getFICAVForReservation(
        props.currentReservation.group_id,
        props.currentReservation.id,
        publicAccessToken
      );
      FICAVamount.value = FICAVdue.data;
      forceUpdate();
    };

    watch(
      () => props.currentReservation,
      () => {
        getDepositToPay();
        getFICAVToPay();
        getReservationOptionsPrices();
      }
    );

    getDepositToPay();
    getFICAVToPay();
    getReservationOptionsPrices();

    return {
      t,
      getRoomsPricings,
      amount,
      detailledDeposits,
      FICAVamount,
      showAdditionalOptions,
      reservationOptionsPrices,
      travelers,
    };
  },
  computed: {
    showReservationOptions() {
      return (
        this.reservationOptions &&
        this.reservationOptions.length > 0 &&
        this.showAdditionalOptions
      );
    },
  },
  methods: {
    getReservationOptionsPriceForTraveler(travelerId) {
      if (this.reservationOptionsPrices.length !== 0) {
        const reservationOptions = this.reservationOptionsPrices.find(
          (x) => x.traveler_id === travelerId
        );
        return "$" + reservationOptions.price;
      }

      return "N/D";
    },
  },
};
</script>

<style>
.card-footer {
  display: flex;
  flex-direction: row-reverse;
}

[dir] .list-group-item {
  background-color: #fff;
  border: 1px solid rgba(34, 41, 47, 0.125);
  padding: 5px;
}

.dark-line-item,
.dark-line-item:hover {
  background-color: #015a9c !important;
  color: white;
}

.children-pricing-border-top {
  border-top: 1px solid #4b4b4b;
}
</style>
